import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  GetAvailabilityQuery,
  OfferFilters,
  SearchHomeboundQuery,
  SearchOutboundQuery,
} from '@codegen/gatewayUtils';
import { useConstants } from '../ConstantContext';
import { useSettings } from '../SettingsContext';
import { getCheapestOffer } from '../utils/searchWidgetContextUtils';
import usePartnerPassengersConfig from './usePassengersConfig';
import useSearchQueryParams from './useSearchQueryParams';

interface Args {
  areHomeboundFiltersDisabled: boolean;
  areOutboundFiltersDisabled: boolean;
  homeboundData?: SearchHomeboundQuery;
  homeboundFilters: OfferFilters | null;
  isHomeboundLoading: boolean;
  isOutboundLoading: boolean;
  outboundData?: SearchOutboundQuery;
  outboundFilters: OfferFilters | null;
}

// This hook is used to update the availability query cache in the flight grid.
// When we do not have lowestFare on the given date, we update the lowestFare for that day with the cheapest results.
const useAvailabilityUpdate = ({
  areHomeboundFiltersDisabled,
  areOutboundFiltersDisabled,
  homeboundData,
  homeboundFilters,
  isHomeboundLoading,
  isOutboundLoading,
  outboundData,
  outboundFilters,
}: Args) => {
  const queryClient = useQueryClient();
  const { partner } = useConstants();
  const { currency } = useSettings();
  const { passengerRules } = usePartnerPassengersConfig();
  const { departureDate, destinations, origins, returnDate } =
    useSearchQueryParams(passengerRules);

  const availabilityQueryKey = [
    'getAvailability',
    {
      partner,
      currencyCode: currency,
      origins,
      destinations,
    },
  ];

  useEffect(() => {
    if (
      (!outboundFilters || areOutboundFiltersDisabled) &&
      outboundData?.searchOutbound?.offersFilters &&
      !isOutboundLoading
    ) {
      const cheapestOffer = getCheapestOffer(
        'outbound',
        outboundData.searchOutbound.offers,
      );

      queryClient.setQueryData(
        availabilityQueryKey,
        (oldData: GetAvailabilityQuery | undefined) => ({
          availability: {
            outbound:
              oldData?.availability?.outbound.map((availabilityNode) =>
                availabilityNode &&
                availabilityNode.date === departureDate?.format('YYYY-MM-DD') &&
                !availabilityNode.lowestFare
                  ? {
                      ...availabilityNode,
                      lowestFare: cheapestOffer?.outboundPricePerPerson,
                    }
                  : availabilityNode,
              ) ?? [],
            homebound: oldData?.availability?.homebound ?? [],
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outboundFilters, outboundData?.searchOutbound?.offersFilters]);

  useEffect(() => {
    if (
      (!homeboundFilters || areHomeboundFiltersDisabled) &&
      homeboundData?.searchHomebound?.offersFilters &&
      !isHomeboundLoading
    ) {
      const cheapestOffer = getCheapestOffer(
        'inbound',
        homeboundData.searchHomebound.offers,
      );

      queryClient.setQueryData(
        availabilityQueryKey,
        (oldData: GetAvailabilityQuery | undefined) => ({
          availability: {
            outbound: oldData?.availability?.outbound ?? [],
            homebound:
              oldData?.availability?.homebound.map((availabilityNode) =>
                availabilityNode &&
                availabilityNode.date === returnDate?.format('YYYY-MM-DD') &&
                !availabilityNode.lowestFare
                  ? {
                      ...availabilityNode,
                      lowestFare: cheapestOffer?.outboundPricePerPerson,
                    }
                  : availabilityNode,
              ) ?? [],
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeboundFilters, homeboundData?.searchHomebound?.offersFilters]);
};

export default useAvailabilityUpdate;
