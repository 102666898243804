import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { gatewayFetcher } from '@codegen/gatewayFetcher';
import {
  Offer,
  SearchHomeboundDocument,
  SearchHomeboundQuery,
  SearchHomeboundQueryVariables,
  SearchOutboundDocument,
  SearchOutboundQuery,
  SearchOutboundQueryVariables,
} from '@codegen/gatewayUtils';
import {
  getGetDeeplinkChargeBreakdownQueryKey,
  getGetItineraryQueryKey,
  useGetDeeplinkChargeBreakdownHook,
  useGetItineraryHook,
} from '@codegen/offerAPI';
import { Sort } from '@shared/types/enums';
import useQueryWithLoadTime from '@ui/hooks/useQueryWithLoadTime';
import { wrapArrayIfNeeded } from '@utils/helperUtils';
import {
  parseQueryString,
  parseQueryStringToObject,
  parseUtmSource,
} from '@utils/queryUtils';
import { useSearchFilters } from '@web/hooks/search/useSearchFilters';
import { transformUTMParam } from '@web/utils/offerUtils';
import {
  constructOffers,
  constructOfferQueryVariables,
} from '@web/utils/search/searchWidgetUtils';
import { useAnalytics } from './AnalyticsContext';
import { useConstants } from './ConstantContext';
import useAvailabilityUpdate from './hooks/useAvailabilityUpdate';
import usePartnerRouter from './hooks/usePartnerRouter';
import usePartnerPassengersConfig from './hooks/usePassengersConfig';
import useSearchQueryParams from './hooks/useSearchQueryParams';
import { useSettings } from './SettingsContext';

export type SplitJourneySearch = {
  activeHomeboundSort: Sort;
  activeOutboundSort: Sort;
  homeboundFilters: ReturnType<typeof useSearchFilters>;
  homeboundOffers: Offer[] | null;
  homeboundOffersFilters:
    | NonNullable<SearchHomeboundQuery['searchHomebound']>['offersFilters']
    | undefined;
  homeboundSelection: Offer | null;
  isHomeboundFetching: boolean;
  isHomeboundLoading: boolean;
  isOneWay: boolean;
  isOutboundFetching: boolean;
  isOutboundLoading: boolean;
  originalNumberOfHomeboundOffers: number | null;
  originalNumberOfOutboundOffers: number | null;
  outboundFilters: ReturnType<typeof useSearchFilters>;
  outboundOffers: Offer[];
  outboundOffersFilters:
    | NonNullable<SearchOutboundQuery['searchOutbound']>['offersFilters']
    | undefined;
  outboundSelection: Offer | null;
  selectHomebound: (offer: Offer | null) => Promise<void>;
  selectOutbound: (offer: Offer | null) => Promise<void>;
  setActiveHomeboundSort: (sort: Sort) => void;
  setActiveOutboundSort: (sort: Sort) => void;
  setFiltersDisabled: (isDisabled: boolean) => void;
  setHomeboundSelection: (offer: Offer | null) => void;
  setOutboundSelection: (offer: Offer | null) => void;
  totalPrice: number;
};

const context = createContext<SplitJourneySearch>({} as SplitJourneySearch);

export default context;

export const SplitJourneySearchProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const {
    sendClearJourneySelectionEvent,
    sendProductClickedEvent,
    sendSearchResultAnalyticsEvent,
  } = useAnalytics();

  const [outboundSelection, setOutboundSelection] = useState<Offer | null>(
    null,
  );

  const [originalNumberOfOutboundOffers, setOriginalNumberOfOutboundOffers] =
    useState<number | null>(null);

  const [originalNumberOfHomeboundOffers, setOriginalNumberOfHomeboundOffers] =
    useState<number | null>(null);

  const [homeboundSelection, setHomeboundSelection] = useState<Offer | null>(
    null,
  );

  const [activeOutboundSort, setActiveOutboundSort] = useState<Sort>(
    Sort.CHEAPEST,
  );

  const [activeHomeboundSort, setActiveHomeboundSort] = useState<Sort>(
    Sort.CHEAPEST,
  );

  /**
   * Some controls, (such as the price slider)
   * Require us to both reset and disable filters for a few render cycles
   * while we fetch new offers and filters.
   * These will then be set to active each time the filter useEffect
   * sets the initial active filters based on offerFilters
   */
  const areOutboundFiltersDisabledRef = useRef(false);
  const areHomeboundFiltersDisabledRef = useRef(false);

  const outboundFilters = useSearchFilters();
  const homeboundFilters = useSearchFilters();

  const queryClient = useQueryClient();
  const { currency } = useSettings();
  const { locale, partner } = useConstants();
  const { push, query } = usePartnerRouter();
  const { passengerRules } = usePartnerPassengersConfig();
  const {
    departureDate,
    destinations,
    isOneWay,
    origins,
    paxTypeAges,
    returnDate,
  } = useSearchQueryParams(passengerRules);

  const getItinerary = useGetItineraryHook();
  const getDeeplinkChargeBreakdown = useGetDeeplinkChargeBreakdownHook();

  const defaultQueryVariableParams = {
    partner,
    language: locale,
    currency,
    residency: parseQueryString(query.residency),
    origins,
    destinations,
    departureDate,
    returnDate,
    paxTypeAges,
    isOneWay,
    limit: 25,
    utmSource: parseUtmSource(query.utm_source),
    carrierCodes: wrapArrayIfNeeded(parseQueryString(query.carrierCodes)),
  };

  const outboundVariables = constructOfferQueryVariables({
    ...defaultQueryVariableParams,
    activeFilters: !areOutboundFiltersDisabledRef.current
      ? outboundFilters.activeFilters
      : null,
    activeSort: activeOutboundSort,
  });

  const homeboundVariables = constructOfferQueryVariables({
    ...defaultQueryVariableParams,
    activeFilters: !areHomeboundFiltersDisabledRef.current
      ? homeboundFilters.activeFilters
      : null,
    activeSort: activeHomeboundSort,
  });

  const {
    data: outboundData,
    isFetching: isOutboundFetching,
    isLoading: isOutboundLoading,
  } = useQueryWithLoadTime<SearchOutboundQuery>(
    ['searchOutbound', { ...outboundVariables }],
    gatewayFetcher<SearchOutboundQuery, SearchOutboundQueryVariables>(
      SearchOutboundDocument,
      outboundVariables,
    ),
    {
      enabled: !query.combo && outboundSelection === null,
      keepPreviousData: true,
      onSuccess: (data, loadTime) => {
        sendSearchResultAnalyticsEvent({
          offersFilters: data.searchOutbound?.offersFilters,
          offers: data.searchOutbound?.offers ?? [],
          currency,
          paxTypeAges,
          activeFilters: outboundFilters.activeFilters,
          activeSort: activeOutboundSort,
          loadTime,
          journeyType: 'outbound',
        });
      },
    },
  );

  const {
    data: homeboundData,
    isFetching: isHomeboundFetching,
    isLoading: isHomeboundLoading,
  } = useQueryWithLoadTime<SearchHomeboundQuery>(
    [
      'searchHomebound',
      {
        // safe typecasting since the query is not enabled when outboundSelection is null
        outboundId: outboundSelection?.journeyId as string,
        ...homeboundVariables,
      },
    ],
    gatewayFetcher<SearchHomeboundQuery, SearchHomeboundQueryVariables>(
      SearchHomeboundDocument,
      {
        outboundId: outboundSelection?.journeyId as string,
        ...homeboundVariables,
      },
    ),
    {
      enabled:
        !query.combo &&
        homeboundSelection === null &&
        outboundSelection !== null &&
        !isOneWay,
      keepPreviousData: true,
      onSuccess: (data, loadTime) => {
        sendSearchResultAnalyticsEvent({
          offersFilters: data.searchHomebound?.offersFilters,
          offers: data.searchHomebound?.offers ?? [],
          currency,
          paxTypeAges,
          activeFilters: homeboundFilters.activeFilters,
          activeSort: activeHomeboundSort,
          loadTime,
          journeyType: 'inbound',
        });
      },
    },
  );

  useAvailabilityUpdate({
    areHomeboundFiltersDisabled: areHomeboundFiltersDisabledRef.current,
    areOutboundFiltersDisabled: areOutboundFiltersDisabledRef.current,
    homeboundData,
    outboundData,
    homeboundFilters: homeboundFilters.activeFilters,
    outboundFilters: outboundFilters.activeFilters,
    isHomeboundLoading: isHomeboundFetching || isHomeboundLoading,
    isOutboundLoading: isOutboundFetching || isOutboundLoading,
  });

  const outboundOffersFilters = outboundData?.searchOutbound?.offersFilters;
  const homeboundOffersFilters = homeboundData?.searchHomebound?.offersFilters;

  // Setting outbound offers filters when available
  useEffect(() => {
    if (
      (!outboundFilters.activeFilters ||
        areOutboundFiltersDisabledRef.current) &&
      outboundOffersFilters &&
      !isOutboundFetching &&
      !isOutboundLoading
    ) {
      /**
       * Before we update the filters, the query cache must be updated
       * with the default offer filters, this is done so that when we update the filters
       * and re-render, a cache hit will occur when the search result query parameters are updated.
       */
      const variables = constructOfferQueryVariables({
        ...defaultQueryVariableParams,
        activeFilters: outboundOffersFilters,
        activeSort: activeOutboundSort,
      });

      setOriginalNumberOfOutboundOffers(
        outboundData.searchOutbound?.offers.length ?? null,
      );

      queryClient.setQueryData(
        ['searchOutbound', { ...variables }],
        () => outboundData,
      );

      // If disabled, enable filters once set
      areOutboundFiltersDisabledRef.current = false;
      outboundFilters.setActiveFilters(outboundOffersFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outboundFilters, outboundOffersFilters]);

  // Setting homebound offers filters when available
  useEffect(
    () => {
      if (
        (!homeboundFilters.activeFilters ||
          areHomeboundFiltersDisabledRef.current) &&
        homeboundOffersFilters &&
        !isHomeboundFetching
      ) {
        /**
         * Before we update the filters, the query cache must be updated
         * with the default offer filters, this is done so that when we update the filters
         * and re-render, a cache hit will occur when the search result query parameters are updated
         */
        const variables = constructOfferQueryVariables({
          ...defaultQueryVariableParams,
          activeFilters: homeboundOffersFilters,
          activeSort: activeHomeboundSort,
        });

        setOriginalNumberOfHomeboundOffers(
          homeboundData.searchHomebound?.offers.length ?? null,
        );

        queryClient.setQueryData(
          [
            'searchHomebound',
            {
              ...variables,
              outboundId: outboundSelection?.journeyId as string,
            },
          ],
          () => homeboundData,
        );

        // If disabled, enable filters once set
        areHomeboundFiltersDisabledRef.current = false;
        homeboundFilters.setActiveFilters(homeboundOffersFilters);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [homeboundFilters, homeboundOffersFilters],
  );

  const navigateToBook = async (offer: Offer) => {
    const [, queryString] = offer.transferURL.split('?');

    const parsedParams = parseQueryStringToObject(queryString);

    // This is not a Dohop url so lets navigate to the transferURL.
    if (
      !parsedParams.combo ||
      !parsedParams.out ||
      !parsedParams.fares ||
      !parsedParams.fee
    ) {
      // eslint-disable-next-line functional/immutable-data
      window.location.href = offer.transferURL;

      return;
    }

    const itineraryParams = {
      combo: parsedParams.combo,
      home: parsedParams.home,
      lang: locale,
      out: parsedParams.out,
    };

    const deeplinkParams = {
      fares: parsedParams.fares,
      fee: parsedParams.fee,
      curr: currency,
    };

    await queryClient.prefetchQuery(
      getGetItineraryQueryKey(itineraryParams),
      async () => await getItinerary(itineraryParams),
      { retry: 2 },
    );

    await queryClient.prefetchQuery(
      getGetDeeplinkChargeBreakdownQueryKey(deeplinkParams),
      async () => await getDeeplinkChargeBreakdown(deeplinkParams),
      { retry: 2 },
    );

    push({
      keepPreviousQuery: false,
      query: { ...parsedParams, currency },
      shallow: true,
    });
  };

  const selectOutbound = async (offer: Offer | null) => {
    // When choosing an outbound flight, we need to reset and disable filters
    // on inbound flights, in case any were active before
    areHomeboundFiltersDisabledRef.current = true;

    if (!offer) {
      sendClearJourneySelectionEvent({ searchType: 'outbound' });
    } else {
      sendProductClickedEvent({
        currency,
        offer,
        offerTags: [],
        position:
          (outboundData?.searchOutbound?.offers.findIndex(
            (o) => o.id === offer.id,
          ) ?? 0) + 1,
        journeyType: 'outbound',
      });
    }
    setOutboundSelection(offer);

    if (offer && isOneWay) {
      await navigateToBook(offer);
    }
  };

  const selectHomebound = async (offer: Offer | null) => {
    setHomeboundSelection(offer);

    if (!offer) {
      sendClearJourneySelectionEvent({ searchType: 'inbound' });
    } else {
      sendProductClickedEvent({
        currency,
        offer,
        offerTags: [],
        position:
          (homeboundData?.searchHomebound?.offers.findIndex(
            (o) => o.id === offer.id,
          ) ?? 0) + 1,
        journeyType: 'inbound',
      });
    }

    if (offer) {
      await navigateToBook(offer);
    }
  };

  const setFiltersDisabled = (isDisabled: boolean) => {
    areOutboundFiltersDisabledRef.current = isDisabled;
    areHomeboundFiltersDisabledRef.current = isDisabled;
  };

  const totalOneWayPrice = outboundSelection?.price ?? 0;
  const totalReturnPrice = homeboundSelection?.price ?? 0;

  return (
    <context.Provider
      value={{
        outboundOffers: constructOffers({
          offers: outboundData?.searchOutbound?.offers,
          utmSource: parseUtmSource(query.utm_source),
          utmMedium: transformUTMParam(query.utm_medium),
          utmCampaign: transformUTMParam(query.utm_campaign),
        }),
        selectOutbound,
        outboundSelection,
        homeboundOffers: homeboundData
          ? constructOffers({
              offers: homeboundData.searchHomebound?.offers,
              utmSource: parseUtmSource(query.utm_source),
              utmMedium: transformUTMParam(query.utm_medium),
              utmCampaign: transformUTMParam(query.utm_campaign),
            })
          : null,
        selectHomebound,
        homeboundSelection,
        isOneWay,
        totalPrice: isOneWay ? totalOneWayPrice : totalReturnPrice,
        outboundFilters,
        homeboundFilters,
        outboundOffersFilters,
        homeboundOffersFilters,
        isOutboundLoading,
        isHomeboundLoading,
        isOutboundFetching,
        isHomeboundFetching,
        activeHomeboundSort,
        activeOutboundSort,
        setActiveHomeboundSort,
        setActiveOutboundSort,
        setFiltersDisabled,
        originalNumberOfOutboundOffers,
        originalNumberOfHomeboundOffers,
        setOutboundSelection,
        setHomeboundSelection,
      }}
    >
      {children}
    </context.Provider>
  );
};

export const useSplitJourneySearch = () => useContext(context);
