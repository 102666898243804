import { useMemo } from 'react';
import { PassengerRulesFragment } from '@codegen/cmsUtils';
import { constructSearchQueryParams } from '@web/utils/search/searchWidgetUtils';
import usePartnerRouter from './usePartnerRouter';

const useSearchQueryParams = (
  passengerRules: PassengerRulesFragment | null | undefined,
) => {
  const { query } = usePartnerRouter();

  const {
    carrierCodes,
    currency,
    departureDate,
    destinations,
    isOneWay,
    origins,
    paxTypeAges,
    residency,
    returnDate,
  } = useMemo(
    () =>
      constructSearchQueryParams(
        {
          ...query,
          origins: Array.isArray(query.origins)
            ? query.origins
            : query.origins?.split(','),
          destinations: Array.isArray(query.destinations)
            ? query.destinations
            : query.destinations?.split(','),
        },
        passengerRules,
      ),
    [passengerRules, query],
  );

  const numberOfSelectedPassengers = Object.keys(paxTypeAges).reduce(
    (prev, curr) => {
      return prev + (paxTypeAges[curr]?.length ?? 0);
    },
    0,
  );

  return {
    origins,
    destinations,
    departureDate,
    returnDate,
    residency,
    currency,
    isOneWay,
    paxTypeAges,
    carrierCodes,
    numberOfSelectedPassengers,
  };
};

export default useSearchQueryParams;
